import React from 'react'
import { MDXTag } from '@mdx-js/tag'


const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="p" components={components}>{`In this video you're going to learn about a UX problem in Stride web application due to distracting favicon & a way to address this by writing & publishing a custom extension.`}</MDXTag>
      <iframe style={{
        "marginTop": "1em"
      }} width="100%" height="400" src="https://www.youtube.com/embed/lw9yk0w7S78" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      <MDXTag name="p" components={components}>{`Links from the video:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://developer.chrome.com/extensions/getstarted"
          }}>{`Get started with extensions`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://github.com/k1LoW/backlog-favicon-changer/"
          }}>{`Example repo`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li"><MDXTag name="a" components={components} parentName="strong" props={{
              "href": "https://github.com/webuniverseio/stride-favicon"
            }}>{`Stride favicon repo`}</MDXTag></MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://developer.chrome.com/extensions/permission_warnings"
          }}>{`Permissions description`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://developer.chrome.com/extensions/content_scripts"
          }}>{`Content scripts description`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://chrome.google.com/webstore/developer/dashboard?pli=1&authuser=1"
          }}>{`Chrome webstore dashboard`}</MDXTag></MDXTag>
      </MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {
  "title": "Creating & publishing a browser extension or How changing a Stride favicon improved my performance by 40%",
  "date": "2018-10-30T23:40:27.000Z",
  "overview": "Small change in stride chat web client improved my performance by 40%. In this article you'll learn how to start working with chrome extensions & how to upload your extension to chrome store.",
  "type": "post"
};
    